@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(Open Sans SemiBold), local(OpenSans-SemiBold), url("open-sans-v18-latin-600.a483cf08.eot#iefix") format("embedded-opentype"), url("open-sans-v18-latin-600.093ebd82.woff2") format("woff2"), url("open-sans-v18-latin-600.d8f059c7.woff") format("woff"), url("open-sans-v18-latin-600.aa86e995.ttf") format("truetype"), url("open-sans-v18-latin-600.a6a9b68d.svg#OpenSans") format("svg");
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(Material Icons), local(MaterialIcons-Regular), url("material-icon-iconfont.98d322b1.woff2") format("woff2");
}

.material-icons {
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  direction: ltr;
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.top-full {
  top: 100%;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.\!mt-1\.5 {
  margin-top: .375rem !important;
}

.\!mt-4 {
  margin-top: 1rem !important;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.\!hidden {
  display: none !important;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-full {
  height: 100%;
}

.min-h-\[30rem\] {
  min-height: 30rem;
}

.min-h-\[70vh\] {
  min-height: 70vh;
}

.min-h-\[90vh\] {
  min-height: 90vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-11 {
  width: 2.75rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.min-w-full {
  min-width: 100%;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-grow {
  flex-grow: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.text-nowrap {
  text-wrap: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-accent {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.border-black\/40 {
  border-color: #0006;
}

.border-black\/50 {
  border-color: #00000080;
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.bg-accent {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.bg-orange-400\/30 {
  background-color: #fb923c4d;
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.text-center {
  text-align: center;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-semibold {
  font-weight: 600;
}

.text-accent {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity, 1));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

html, body {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  overflow-y: auto;
}

.buy-me-a-coffee-button {
  color: #fff;
  background-color: #f1337f;
  transition: all .3s;
}

.buy-me-a-coffee-button:hover {
  filter: brightness(125%);
  color: #fff;
}

.settings-input[type="text"], .settings-input[type="number"] {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
  padding: 0 .15rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

select.settings-input {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

::backdrop {
  backdrop-filter: blur(15px) brightness(50%);
}

.divider {
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity, 1));
  align-items: center;
  margin: .375rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 1rem;
  display: flex;
  position: relative;
}

.divider:before, .divider:after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity, 1));
  flex: 1;
  height: 1px;
  display: block;
}

.divider:before {
  margin-right: .375rem;
}

.divider:after {
  margin-left: .375rem;
}

.\*\:list-none > * {
  list-style-type: none;
}

.hover\:block:hover {
  display: block;
}

.hover\:border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.peer:hover ~ .peer-hover\:block, .aria-expanded\:block[aria-expanded="true"] {
  display: block;
}

.aria-expanded\:flex[aria-expanded="true"] {
  display: flex;
}

.aria-expanded\:bg-neutral-200[aria-expanded="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity, 1));
}

.aria-selected\:border-b[aria-selected="true"] {
  border-bottom-width: 1px;
}

.group[aria-expanded="true"] .group-aria-expanded\:block {
  display: block;
}

.group[aria-expanded="true"] .group-aria-expanded\:hidden {
  display: none;
}

.peer[aria-expanded="true"] ~ .peer-aria-expanded\:block {
  display: block;
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:\!grid {
    display: grid !important;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:border {
    border-width: 1px;
  }

  .md\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 1024px) {
  .lg\:h-full {
    height: 100%;
  }

  .lg\:min-h-\[50vh\] {
    min-height: 50vh;
  }

  .lg\:w-10\/12 {
    width: 83.3333%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:resize-y {
    resize: vertical;
  }
}

@media (width >= 1280px) {
  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-9\/12 {
    width: 75%;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:items-stretch {
    align-items: stretch;
  }
}

.\[\&\>div\>\*\:nth-child\(1\)\]\:col-span-2 > div > :first-child {
  grid-column: span 2 / span 2;
}

.\[\&\>div\>\*\:nth-child\(2\)\]\:col-span-5 > div > :nth-child(2), .\[\&\>div\>\*\:nth-child\(3\)\]\:col-span-5 > div > :nth-child(3) {
  grid-column: span 5 / span 5;
}

.\[\&\>div\]\:grid > div {
  display: grid;
}

.\[\&\>div\]\:w-full > div {
  width: 100%;
}

.\[\&\>div\]\:grid-cols-1 > div {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.\[\&\>div\]\:grid-cols-12 > div {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.\[\&\>div\]\:items-center > div {
  align-items: center;
}

.\[\&\>div\]\:border > div {
  border-width: 1px;
}

.\[\&\>div\]\:border-b > div {
  border-bottom-width: 1px;
}

.\[\&\>div\]\:border-black\/40 > div {
  border-color: #0006;
}

.\[\&\>div\]\:px-4 > div {
  padding-left: 1rem;
  padding-right: 1rem;
}

.\[\&\>div\]\:py-2 > div {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media (width >= 768px) {
  .\[\&\>div\]\:md\:grid-cols-2 > div {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.\[\&_img\]\:mt-2 img {
  margin-top: .5rem;
}

.\[\&_img\]\:max-h-\[500px\] img {
  max-height: 500px;
}

@media (width >= 768px) {
  .\[\&_img\]\:md\:mt-auto img {
    margin-top: auto;
  }
}

.\[\&_pre\]\:inline-block pre {
  display: inline-block;
}

.\[\&_pre\]\:max-w-full pre {
  max-width: 100%;
}

.\[\&_pre\]\:overflow-scroll pre {
  overflow: scroll;
}

.\[\&_pre\]\:border pre {
  border-width: 1px;
}

.\[\&_pre\]\:border-black pre {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.\[\&_pre\]\:p-1 pre {
  padding: .25rem;
}

.\[\&_pre\]\:font-mono pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.\[\&_pre\]\:text-sm pre {
  font-size: .875rem;
  line-height: 1.25rem;
}

.\[\&_pre\]\:text-neutral-600 pre {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity, 1));
}
/*# sourceMappingURL=404.5278dd89.css.map */
